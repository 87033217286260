/* General table styles */
 
  /* Group-specific styles */
  .group-iva {
    background-color: #e8f5e92b !important; 
  }
  
  .group-rete-iva {
    background-color: #ffecb33d !important;
  }
  
  .group-rete-ica {
    background-color: #e1f5fe38 !important;
  }
  
  .group-rete-fuente {
    background-color: #ede7f63b !important;
  }
  
  .group-base-iva {
    background-color: #d1c4e93b !important;
  }
  
  .group-base-ica {
    background-color: #c8e6c936 !important;
  }
  
  .group-base-fuente {
    background-color: #ffe0b230 !important;
  }
  
  /* Table borders */
  table tr th, table tr td {
    border: 0.5px solidrgba(204, 204, 204, 0.185)c;
  }
  
  /* Hover effect for rows */
  tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    table {
      font-size: 12px;
    }
  
    th, td {
      padding: 8px;
    }
  }
  