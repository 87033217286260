:root {
  --bs-table-bg:#2B3361 !important;
}
html,body{
  height: "100vh";
  background-color: #ddd;
  --bs-table-bg:#2B3361 !important;
}

.badge{
  background-color: #333;
  padding: 6px;
}

.Abierta{
  color: red;
}

.Cerrada{
  color: green;
}

.simula_input{
  padding: .275rem .0rem !important;
  border-bottom-width: 0px;
  border-radius: 0 !important;
  border-bottom: 1px solid #2B3361;
  outline: none;
}
.text-h7{
  line-height: 14px;
  font-size: 12px;
}

.bg-gray{
  background-color: #f3f3f3;
}

.Container-observaciones{
  margin-top: 10px;
  width: 100% !important;
  background: #F5F5F5;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  border-radius: 1px solid #969696;
}
.container-input-observaciones{
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
}
.container-input-observaciones>textarea{
  width: 100%;
  min-height: 100px;
  border:none;
  border-radius: 4px 4px 0 0 !important;
  padding: 20px;
  background: #FBFCFC !important;
}
textarea:focus, input:focus, input[type]:focus{
  box-shadow: none;
  outline: 0 none;
}
.opciones-Observaciones{
  padding: 4px 40px;
  font-size: 1.4rem;
  color: #6B6B6B;
  margin-bottom: 30px;
}
.comentario-observacion{
  width: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  padding: 4px;
  margin-bottom: 10px;
}
.img-comentario{
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.img-comentario>img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.texto-observacion{
  width: calc(90% - 70px) !important;
  font-size: 0.8rem;
  text-align: justify;
  text-justify: inter-word;
}
.texto-observacion-open{
  width: calc(90% - 70px) !important;
  font-size: 0.8rem;
  text-align: justify;
  text-justify: inter-word;
}

.texto-observacion-close{
  max-height: 100px;
  overflow: hidden;
}
.acciones-comentarios{
  width: calc(10%) !important;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF40D7;
}
.uploadFile{
  width: 100%;
  height: 100%;
}

.inputfile{
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
	cursor: pointer;
  left: 0;
  top: 0;
}

.btn-primary {
    color: #fff;
    background-color:#2B3361 !important;
    border-color:#2B1160 !important;
}

.btn-danger {
  /*background-color:#2B3361 !important;
  border-color:#2B1160 !important;*/
}

.border-danger {
  border-color:#2B3361 !important;
}

.bg-primary {
  background-color:#2B3361 !important;
  --bs-bg-opacity: 1;
  --bs-table-bg:#2B3361 !important;
}

.btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2B3361;
    border-color: #2B1160;
}

.position-absolute-shoppingcart{
  text-align: center;
  font-size: 50%;
  padding:0 2px ;
  width: 15px;
  height: 15px;
  background-color: red;
  right: 1px;
  top: 4px;
  border-radius: 10px;
  font-weight: bold;
}

.preview{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;
  border:solid 2px #fff;
}

.cursor-pointer{
  cursor: pointer;
}

.fileInput{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.min-height{
  min-height: 200px;
}
.min-height400{
  min-height: 400px;
}

.min-height-70vh{
  min-height: 70vh;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link {
  color: #fff;  
  text-decoration: none;
}

.link:hover {
  color: #fff;  
}

.right{
  /* border-left: 3px solid #ddd; */
  /* background-color: #f2f2f2;
  transition: opacity 1s ease-in; */
}

.transition{
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  /*overflow: hidden;*/
}
.transition.transition-active {
  opacity: 1;
  height: auto;
}

.form-control{
  border: 0;
  border-radius: 0 !important;
  border-bottom: 1px solid #2B3361;
  outline: none;
}

.form-control:focus {
  border-bottom: 2px solid #2B3361;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.position-controls{
  position: absolute;
  width: 150px;
  height: 32px;
  background-color: #F9F9FA;
  right:0;
}

.form-control {
  /*font-size: 12px !important;*/
  padding: .275rem .0rem !important;
}

.form-control-label{
  font-size: 11px;
  font-weight: bold;
  color: #2B3361 !important;
  text-decoration: none;
}

.max-height-100{
  max-height: 100px;
  overflow-y: scroll;
}

.autocomplete-z-index{
  z-index: 10;
}

.iconify{
  font-size: 25px;
}

/* other styles */

.bg-blue{
  background-color: #2B3361;
}

  @media (max-width: 480px) {
    .mh-1{
      min-height: 100px;
    }

    .mh-2{
      min-height: 200px;
    }

    .mh-3{
      min-height: 300px;
    }
  }
